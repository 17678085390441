import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import logo from './logo.svg';

function App() {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        const rect = videoElement.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
{/*       <header className="App-header">
        <a href="/">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </header> */}
      <section className="Splash-hero">
        <h1>För att lärare ska få mer tid med elever</h1>
        <h2>AI för en bättre skolan</h2>
        <button onClick={() => scrollToSection('services')}>Tjänster</button>
      </section>
      <section id="services" className="Services">
        <h2>Matematikgeneratorn</h2>
        {!videoLoaded && <div className="video-placeholder"></div>}
        <video
          ref={videoRef}
          controls
          muted
          onLoadedData={() => setVideoLoaded(true)}
          style={{ display: videoLoaded ? 'block' : 'none' }}
        >
          <source src="https://devierapublic.blob.core.windows.net/coms/Matteprovsgenerator teaser.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
      <footer className="Footer">
        <a href="/">
          <img src={logo} className="Footer-logo" alt="logo" />
        </a>
        <p>© 2024 Deviera AB. All rights reserved.</p>
        <p>Stockholm, Sweden</p>
        <p>contact@deviera.ai</p>
      </footer>
    </div>
  );
}

export default App;
